<script setup>
import { useNuxtApp } from '#imports';

const { $tp } = useNuxtApp();

import PopupCenter from '@/components/layout/popup/PopupCenter.vue';
import UiButton from '@/components/ui/button/UiButton.vue';

const props = defineProps({
  closeModal: {
    type: Function,
    default: undefined,
  },
  heading: {
    type: String,
    default: undefined,
  },
  description: {
    type: String,
    default: undefined,
  },
  buttonsData: {
    type: Array,
    default: () => [],
  },
});

function actionButton(clb) {
  clb?.(props.closeModal);
}
</script>
<template>
  <PopupCenter full-width-mobile>
    <template #default>
      <div class="popup-info">
        <div v-if="props.heading" class="popup-info__heading">
          <h3
            class="popup-info__font popup-info__font--heading"
            v-html="$tp(props.heading)"
          />
        </div>

        <div class="popup-info__info">
          <div v-if="props.description" class="popup-info__description">
            <p
              class="popup-info__font popup-info__font--description"
              v-html="$tp(props.description)"
            />
          </div>

          <div
            v-if="props.buttonsData?.length"
            class="popup-info__actions"
            :class="{
              'popup-info__actions--wrap': props.buttonsData?.length > 2,
            }"
          >
            <UiButton
              v-for="(item, index) in props.buttonsData"
              :key="index"
              class="popup-info__button"
              :text="item.text"
              :theme="item.theme"
              @click="actionButton(item.action)"
            />
          </div>
        </div>
      </div>
    </template>
  </PopupCenter>
</template>
<style scoped lang="scss">
.popup-info {
  $parent: &;

  display: flex;
  flex-direction: column;
  width: em(608);
  margin-top: em(32);
  margin-bottom: em(32);
  background-color: $color-white;
  border-radius: em(48);

  @include media-breakpoint-down(sm) {
    width: 100%;
    margin-top: auto;
    margin-bottom: 0;
    border-radius: em(32) em(32) 0 0;
  }

  &__font {
    &--heading {
      font-size: em(30);
      font-weight: 700;
      line-height: 1;
      letter-spacing: -0.03em;
    }

    &--description {
      font-size: em(18);
      line-height: 1.3;

      &:deep(strong) {
        font-weight: 700;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: em(36);
    padding: em(36);

    @include media-breakpoint-down(sm) {
      gap: em(24);
      padding: em(24);
    }
  }

  &__heading {
    display: flex;
    flex-direction: column;
    gap: em(8);
    padding: em(36) em(88) em(36) em(36);
    background-color: $color-grey-1;
    border-bottom: 1px solid rgba($color-black, 0.1);
    border-radius: em(48);

    @include media-breakpoint-down(sm) {
      padding: em(32) em(76) em(32) em(24);
      border-radius: em(32);
    }
  }

  &__actions {
    display: flex;
    gap: em(8);

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    &--wrap {
      flex-wrap: wrap;
    }
  }

  &__button {
    flex-grow: 1;
    min-width: calc(50% - #{em(4)});

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}
</style>
